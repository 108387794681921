import apiimagemweb from '@/services/api-recebimentoweb';

let findTimeout = null;

export default {
  namespaced: true,

  state: {
    list: [],
    numerocontratomp: null,
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {
    async find(_, {
      where, ...params
    } = {}) {
      const [{ data }, { data: { count: total } }] = await Promise.all([
        apiimagemweb.get('contrato', { params: { ...where, ...params } }),
        apiimagemweb.get('contrato/count', { params: where }),
      ]);

      return { data, total };
    },

    // CONSULTA COM CONTROLE DE CACHE
    async findWithCache({ commit, state }, { force, ttl } = {}) {
      if (!force && state.list?.length > 0) {
        return;
      }

      clearTimeout(findTimeout);

      const { data } = await apiimagemweb.get('contrato');

      commit('SET_LIST', data);

      if (ttl === Infinity) return;

      findTimeout = setTimeout(() => {
        commit('SET_LIST', null);
      }, ttl ?? 1000 * 60); // TTL -> Time To Live
    },

    async setNumeroContrato({ commit }, { numerocontrato } = {}) {
      console.log(numerocontrato);
      commit('SET_NUMERO_CONTRATO', numerocontrato);
    },

  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },

    SET_NUMERO_CONTRATO(state, numerocontrato) {
      console.log(this.state.numerocontratomp, numerocontrato);
      state.numerocontratomp = numerocontrato;
    },
  },
};
